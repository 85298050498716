import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import { GatsbyImage } from 'gatsby-plugin-image';
import styled, { keyframes } from 'styled-components';
import { Tooltip } from 'antd';
import { FaPlus } from 'react-icons/fa';

// component
import Layout from '../components/layout';
import Seo from '../components/seo';
import MiniHeader from '../components/mini-header';

// img
import headerBackground from '../images/airbarrier/header-airbarrier.jpg';

// style-component
const Airbarrier = styled.section`
  padding: 2em 0;
`;

const AirbarrierIntro = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr;

  @media only screen and (max-width: 959px) {
    grid-template-columns: 1fr;
    grid-template-rows: 0.8fr 1fr;
  }
`;

const AirbarrierIntroText = styled.div`
  line-height: 2.5em;
`;

const AirbarrierIntroSystem = styled.div`
  text-align: center;
  position: relative;
`;

const AirbarrierWrapperSystem = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;
const AirbarrierP = styled.p`
  color: #999999;
  font-size: 1rem;
  font-weight: 100;
  line-height: initial;
  padding-right: 1.5em;
`;
const AirbarrierSystems = styled.div`
  position: relative;
  top: 0;
  right: 0;
  left: 0;
  bottom: 0;
`;
const bounce = keyframes`
  0% {
    transform: scale(1);
  }
  100% {
    transform: scale(1.05);
  }
`;

const AirIcon1 = styled.div`
  width: 30px;
  height: 30px;
  background-color: #4caf4f;
  border-radius: 50% 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 0 0;
  animation: ${bounce} 1s ease-in-out both infinite alternate;
  position: absolute;
  top: 13%;
  left: 15.324%;
  &:hover {
    background-color: #ffd460;
    animation-play-state: paused;
  }

  @media only screen and (max-width: 500px) {
    width: 23px;
    height: 23px;
    position: absolute;
    top: 5%;
    left: 15.324%;
  }
  @media only screen and (min-width: 767px) {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 13%;
    left: 15.324%;
  }
  @media only screen and (min-width: 1200px) {
    width: 30px;
    height: 30px;
    position: absolute;
    top: 13%;
    left: 15.324%;
  }
`;

const AirIcon2 = styled.div`
  width: 30px;
  height: 30px;
  background-color: #4caf4f;
  border-radius: 50% 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 0 0;
  animation: ${bounce} 1s ease-in-out both infinite alternate;
  position: absolute;
  left: 19%;
  top: 60%;
  &:hover {
    background-color: #ffd460;
    animation-play-state: paused;
  }
`;
const AirIcon3 = styled.div`
  width: 30px;
  height: 30px;
  background-color: #4caf4f;
  border-radius: 50% 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 0 0;
  animation: ${bounce} 1s ease-in-out both infinite alternate;
  position: absolute;
  left: 35.234%;
  top: 38.234%;
  &:hover {
    background-color: #ffd460;
    animation-play-state: paused;
  }
`;
const AirIcon4 = styled.div`
  width: 30px;
  height: 30px;
  background-color: #4caf4f;
  border-radius: 50% 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 0 0;
  animation: ${bounce} 1s ease-in-out both infinite alternate;
  position: absolute;
  right: 31%;
  top: 13%;
  &:hover {
    background-color: #ffd460;
    animation-play-state: paused;
  }
`;

const AirIcon5 = styled.div`
  width: 30px;
  height: 30px;
  background-color: #4caf4f;
  border-radius: 50% 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 0 0;
  animation: ${bounce} 1s ease-in-out both infinite alternate;
  position: absolute;
  right: 31%;
  top: 61%;
  &:hover {
    background-color: #ffd460;
    animation-play-state: paused;
  }
`;

const AirIcon6 = styled.div`
  width: 30px;
  height: 30px;
  background-color: #4caf4f;
  border-radius: 50% 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 0 0;
  animation: ${bounce} 1s ease-in-out both infinite alternate;
  position: absolute;
  right: 35.345%;
  top: 79%;
  &:hover {
    background-color: #ffd460;
    animation-play-state: paused;
  }
`;

const AirIcon7 = styled.div`
  width: 30px;
  height: 30px;
  background-color: #4caf4f;
  border-radius: 50% 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transform-origin: 0 0;
  animation: ${bounce} 1s ease-in-out both infinite alternate;
  position: absolute;
  right: 10%;
  top: 61%;
  &:hover {
    background-color: #ffd460;
    animation-play-state: paused;
  }
`;

const AirbarrierTitle = styled.div`
  font-size: 2em;
  font-weight: 100;
  padding: 0 0 2em 0;
`;

const AirbarrierSubtitle = styled.span`
  font-size: 1.2em;
  font-weight: 500;
`;

const AirBarrier = React.memo(() => {
  const data = useStaticQuery(graphql`
    {
      airbarrier: file(relativePath: { eq: "airbarrier/airbarrier-system.png" }) {
        childImageSharp {
          gatsbyImageData(quality: 100, layout: FULL_WIDTH)
        }
      }
    }
  `);

  return (
    <Layout>
      <Seo title="Barrera de Aire y Humedad" />
      <MiniHeader text="Barrera de Aire y Humedad" background={headerBackground} />
      <Airbarrier>
        <div className="container">
          <AirbarrierSystems>
            <AirbarrierTitle>
              <span>StoGuard® Air and Moisture Barrier Systems</span>
            </AirbarrierTitle>
            <AirbarrierIntro>
              <AirbarrierIntroText>
                <AirbarrierSubtitle>
                  Seamless protection for the entire wall assembly.
                </AirbarrierSubtitle>
                <AirbarrierP>
                  Air Barriers are required in building walls, regardless of region or climate.
                  Controlling air leakage through the building envelope is critical to achieving
                  high building energy efficiency. Research has shown that air barriers can play a
                  larger role in energy efficiency than increasing insulation thickness. But unlike
                  building wrap barriers that are penetrated by staples and fasteners for their
                  attachment, the StoGuard® System provides seamless control over air and moisture
                  to improve building performance and occupant comfort.
                </AirbarrierP>
              </AirbarrierIntroText>
              <AirbarrierIntroSystem>
                <GatsbyImage
                  image={data.airbarrier.childImageSharp.gatsbyImageData}
                  alt="Sto-AirBarrier"
                />
                <AirbarrierWrapperSystem>
                  <Tooltip title="Substrate">
                    <AirIcon1>
                      <FaPlus
                        style={{
                          fontSize: '13px',
                          color: '#fff',
                        }}
                      />
                    </AirIcon1>
                  </Tooltip>
                  <Tooltip title="Barrera de Aire y Humedad" placement="left">
                    <AirIcon2>
                      <FaPlus
                        style={{
                          fontSize: '13px',
                          color: '#fff',
                        }}
                      />
                    </AirIcon2>
                  </Tooltip>
                  <Tooltip title="Joint Treatment" placement="left">
                    <AirIcon3>
                      <FaPlus
                        style={{
                          fontSize: '13px',
                          color: '#fff',
                        }}
                      />
                    </AirIcon3>
                  </Tooltip>
                  <Tooltip title="Joint Treatment" placement="left">
                    <AirIcon4>
                      <FaPlus
                        style={{
                          fontSize: '13px',
                          color: '#fff',
                        }}
                      />
                    </AirIcon4>
                  </Tooltip>
                  <Tooltip title="Rough Opening">
                    <AirIcon5>
                      <FaPlus
                        style={{
                          fontSize: '13px',
                          color: '#fff',
                        }}
                      />
                    </AirIcon5>
                  </Tooltip>
                  <Tooltip title="Transition Membrane" placement="left">
                    <AirIcon6>
                      <FaPlus
                        style={{
                          fontSize: '13px',
                          color: '#fff',
                        }}
                      />
                    </AirIcon6>
                  </Tooltip>
                  <Tooltip title="Rough Opening Protection">
                    <AirIcon7>
                      <FaPlus
                        style={{
                          fontSize: '13px',
                          color: '#fff',
                        }}
                      />
                    </AirIcon7>
                  </Tooltip>
                </AirbarrierWrapperSystem>
              </AirbarrierIntroSystem>
            </AirbarrierIntro>
          </AirbarrierSystems>
        </div>
      </Airbarrier>
    </Layout>
  );
});

export default AirBarrier;
